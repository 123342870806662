import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`*`}{`Friday, December 29th: The Ville will be closed 1-3:30 for the UL
vs UK game`}{`*`}{`*`}</strong></p>
    <p><em parentName="p">{`Last Day of Metcon Week`}</em></p>
    <p>{`For time:\\
10 box jumps (42/32″)\\
10 bar muscle-ups\\
20 kettlebell snatches (53/35)\\
20 one-legged squats, alternating\\
30 toes-to-bars\\
30 overhead squats (75/55)\\
40 GHD sit-ups\\
40 medicine-ball cleans (20/14)\\
50 burpees\\
50 triple-unders`}</p>
    <p><em parentName="p">{`*`}{`compare to 10/17/15`}</em></p>
    <p><strong parentName="p">{`*`}{`Remaining Holiday Schedule`}{`*`}</strong></p>
    <p><em parentName="p">{`-Sunday, Dec 31st: 12:30 class`}</em></p>
    <p><em parentName="p">{`-Monday, January 1st: Free New Years Day class at 10:30!  No open gym,
all other classes are cancelled.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Get ready for the 3rd Annual Pre Open at The Ville!  Starting the
first week of January.  RX, Scaled, and Masters over 50 Divisions.
 Email Daniel in order to sign up and be added to the google drive in
order to view the standings.`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`Saturday, January 13th is Cardinal Fitness Day at the Yum!.  The
Cards play Virginia Tech at 4:00pm.  With the following link you can get
discounted tickets as a CrossFit the Ville member!  After the game there
will be a CrossFit wod on the court along with several other workout
sessions.  Lets get a big group together and represent The Ville at the
Yum center!  Family is invited as well.`}</strong></p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://fevo.me/fitday"
        }}>{`https://fevo.me/fitday`}</a></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      